import handleResponse from 'shared/utils/fetch';

export class UseProtection {
  constructor(root) {
    /** @protected {!Element} */
    this.form_ = root;
    this.isSending_ = false;

    // Setup handlers
    this.submitHandler_ = (evt) => this.onSubmit(evt);

    this.initialize();
    this.initialSyncWithDOM();
  }

  initialize() {
    // Find error container
    // this.inputField_ = this.form_.querySelector('[type=password]');
  }

  initialSyncWithDOM() {
    console.log();
    // Add mouse event
    this.form_.addEventListener('submit', this.submitHandler_);
  }

  onSubmit(evt) {
    evt.preventDefault();
    if (this.isSending_) {
      return;
    }

    this.isSending_ = true;

    // Collect data
    const formData = new FormData(evt.target);

    fetch(`/${formData.get('brand_slug')}/password/check/`, {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
      credentials: 'same-origin',
      body: formData,
    })
      .then(handleResponse)
      .then((res) => {
        if (res.status === 200) {
          // Open asset in new tab
          window.open(res.asset_url, '_blank');
          // Unfocus on input
          this.form_.elements['password'].blur();
        } else {
          this.form_.classList.add('has-error');
          // Set timeout for error indication
          setTimeout(() => {
            // Remove classes
            this.form_.classList.remove('has-error');
            // Clear input
            this.form_.elements['password'].value = '';
          }, 1000);
        }
      })
      .catch((err) => {
        console.log('error details: ', err);
        throw err;
      })
      .then(() => {
        // ALWAYS
        this.isSending_ = false;
      });
  }
}
