// Webpack Scss styles loader - Please, keep here
// This will be removed before generating product files automatically
// import 'babel-polyfill';
import * as Sentry from '@sentry/browser';
import 'formdata-polyfill';
import 'whatwg-fetch';
import { initSentry } from '../integrations/sentry';
import { UseProtection } from './UseProtection';
import { findParent } from './utils/helpers';

const css = require('./main.scss');

if (process.env.NODE_ENV === 'production') {
  initSentry();

  Sentry.configureScope((scope) => {
    scope.setTag('scene', 'Brand');
  });
}

// MODULES

const AutoplayPlayer = (function AutoplayPlayerWrapper() {
  const defaults = {};

  const AtpPlayer = {
    create(el, options) {
      const instance = Object.create(AtpPlayer);
      instance.init(el, options);
    },

    init(el, options) {
      this.options = Object.assign({}, defaults, options);
      this.el = el;

      if (options.type === 'YT') {
        // Comment: we are loading the "YT" library in HTML script tag
        // eslint-disable-next-line no-undef
        this.player = new YT.Player(el);
      } else if (options.type === 'VIMEO') {
        // Comment: we are loading the "Vimeo" library in HTML script tag
        // eslint-disable-next-line no-undef
        this.player = new Vimeo.Player(el);
      } else {
        console.error('Wrong player specified!');
      }

      this.isPlaying = true;
      this.videoParent = findParent(el, '.bp-player-inner');
      if (!this.options.hideControls) this.videoParent.classList.add('autoplay-ctrls');
      this.videoParent.classList.add('is-playing');

      this.attachHandlers();
    },

    togglePlaying(ev) {
      if (this.isPlaying) {
        this.videoParent.classList.remove('is-playing');
        this.isPlaying = false;
        if (this.options.type === 'YT') {
          this.player.pauseVideo();
        } else if (this.options.type === 'VIMEO') {
          this.player.pause();
        }
      } else {
        this.videoParent.classList.add('is-playing');
        this.isPlaying = true;
        if (this.options.type === 'YT') {
          this.player.playVideo();
        } else if (this.options.type === 'VIMEO') {
          this.player.play();
        }
      }
    },

    attachHandlers() {
      // Disable click if controls are hidden.
      if (!this.options.hideControls) {
        this.videoParent.addEventListener('click', this.togglePlaying.bind(this));
      }
    },
  };

  return AtpPlayer;
})();

/**
 * Set up the BAM dropdown on live brand.
 * - the dropdown is handeled by CSS only
 * - we want to prevent default on dropdown link click
 * - we want to hide the dropdown if clicked on "not now"
 * - we want to ignore this dropdown and and never show it again for this browser
 */
function initBamDropdown() {
  const bamWrap = document.getElementById('brand-bam-wrap');
  const bamTrigger = document.getElementById('brand-bam-trigger');
  const bamIgnoreTrigger = document.getElementById('brand-bam-ignore-trigger');

  const ignoreLocalStorageKey = 'BP:hide-bam-live-brand';
  const localStorageIgnoreValue = localStorage.getItem(ignoreLocalStorageKey);

  // If no bam wrappers found stop execution
  if (!bamWrap || !bamTrigger) {
    return;
  }

  // on init, we hide it as if it is ignored, we don't want to show it at all.
  // but if it is not ignored, we want to show it.
  if (bamWrap.classList.contains('hide') && !localStorageIgnoreValue) {
    bamWrap.classList.remove('hide');
  }

  // stop the a tag to put '#' in the url
  bamTrigger.addEventListener('click', (ev) => {
    ev.preventDefault();
  });

  // if bam dropdown incomplete error out to sentry and stop
  if (!bamIgnoreTrigger) {
    Sentry.captureException(new Error('Live brand BAM dropdown incompletely implemented'), {
      extra: {
        location: window.location,
        hasBamIgnoreTrigger: Boolean(bamIgnoreTrigger),
      },
    });

    return;
  }

  function handleIgnoreClick(ev) {
    ev.preventDefault();
    localStorage.setItem(ignoreLocalStorageKey, Date.now());
    bamWrap.classList.add('hide');
  }

  bamIgnoreTrigger.addEventListener('click', handleIgnoreClick);
}

document.addEventListener('DOMContentLoaded', () => {
  // BAM dropdown on live brand
  initBamDropdown();

  //
  // Handle protected buttons
  //
  const protectedEl = [...document.querySelectorAll('.UseProtection')];
  protectedEl.map((el) => {
    new UseProtection(el);
  });

  const vimeoPlayers = [...document.querySelectorAll('.vimeo-autoplay')];
  if (vimeoPlayers) {
    vimeoPlayers.forEach((el) => {
      const hideControls = el.classList.contains('bp-nocontrols');
      AutoplayPlayer.create(el, { type: 'VIMEO', hideControls });
    });
  }

  window.onYouTubeIframeAPIReady = function () {
    const ytPlayers = [...document.querySelectorAll('.yt-autoplay')];
    if (ytPlayers) {
      ytPlayers.forEach((el) => {
        const hideControls = el.classList.contains('bp-nocontrols');
        AutoplayPlayer.create(el, { type: 'YT', hideControls });
      });
    }
  };

  // SPECIFIC

  // Scroll to anchor links without changing url
  const anchorLinks = Array.from(
    document.querySelectorAll("a.canvas-link[href^='#'], a.brand-menu-link")
  );
  anchorLinks.forEach((anchorLink) => {
    anchorLink.onclick = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      const targetId = anchorLink.getAttribute('href').substring(1);
      window.scrollTo(0, document.getElementById(targetId).offsetTop);
    };
  });

  // Menu open/close
  const toggleElems = Array.from(document.getElementsByClassName('brand-menu-toggle'));
  const menuElems = Array.from(document.getElementsByClassName('brand-menu'));
  toggleElems.forEach((toggleElem) => {
    toggleElem.onclick = (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      menuElems.forEach((e) => e.classList.toggle('is-open'));
    };
  });

  // Close when clicking outside the menu
  const brandContainer = document.getElementsByClassName('bp-guide')[0];
  if (brandContainer) {
    brandContainer.onclick = (ev) => {
      if (findParent(ev.target, '.brand-menu') === null) {
        menuElems.forEach((e) => e.classList.remove('is-open'));
      }
    };
  }

  const brandMenuData = document.getElementById('brand-menu-data');
  const appearMenu = () => {
    if (brandMenuData === null) {
      // No menu, nothing to do
      return;
    }

    const appearIx = brandMenuData.getAttribute('data-appear-ix');
    if (appearIx === '0') {
      // Show it right away
      menuElems.forEach((e) => e.classList.add('is-visible'));
      return;
    }

    const targetDiv = document.getElementById(`section-ix-${appearIx}`);
    if (!targetDiv) {
      // No target, probably appear row is set higher than number of rows.
      return;
    }

    // Wait until scrolled past target row before showing the menu
    const triggerY = targetDiv.offsetTop;

    const handleScroll = () => {
      if (window.scrollY >= triggerY) {
        menuElems.forEach((e) => e.classList.add('is-visible'));
        document.removeEventListener('scroll', handleScroll);
      }
    };

    document.addEventListener('scroll', handleScroll);
  };

  appearMenu();
});

if (process.env.NODE_ENV === 'development') {
  console.log('Working from brand JS');
}
