// Check if response is in JSON
function handleJSONResponse(response) {
  return response.json().then(function(json) {
    if (response.ok) {
      if (response.status === 400) {
        throw new Error(json.message);
      }
      return json;
    } else {
      return Promise.reject(
        Object.assign({}, json, {
          status: response.status,
          statusText: response.statusText,
        })
      );
    }
  });
}

// Check if response is in TXT or XML
function handleTextResponse(response) {
  return response.text().then(function(text) {
    if (response.ok) {
      return text;
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        err: text,
      });
    }
  });
}

// A complete handle response check
export default function handleResponse(response) {
  let contentType = response.headers.get('content-type');
  // console.log('responsehandler:', contentType, response);
  if (contentType.includes('application/json')) {
    return handleJSONResponse(response);
  } else if (contentType.includes('text/html')) {
    return handleTextResponse(response);
  } else {
    // Other response types as necessary. I haven't found a need for them yet though.
    throw new Error(`Sorry, content-type ${contentType} not supported`);
  }
}
