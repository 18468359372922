import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

/**
 * Initializer function to start sentry reporting
 */
function initSentry() {
  Sentry.init({
    dsn: 'https://8af4688de7424b0da0a8e64bfa756a41@o87377.ingest.sentry.io/4504174275067904',
    tunnel: '/bugs/',
    release: '0.0.1',
    environment: process.env.NODE_ENV,
    initialScope: {
      tags: {
        version: 'legacy',
      },
    },
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['warn', 'error'],
      }),
      // new BrowserTracing({
      //   routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      //     React.useEffect,
      //     useLocation,
      //     useNavigationType,
      //     createRoutesFromChildren,
      //     matchRoutes
      //   ),
      // }),
      // new SentryRRWeb(),
    ],
    // tracesSampleRate: 1.0,
  });
}

/**
 * log the error to sentry manually
 */
export function logException(e, context) {
  Sentry.withScope((scope) => {
    scope.setExtra('debug', context);
    Sentry.captureException(e);
  });
}

export { initSentry };
